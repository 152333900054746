/* purgecss start ignore */
html {
    font-size: 16px;
}

@media screen and (min-width: 768px) {
    html {
        font-size: 20px;
    }
}
h1 {
    @apply font-headline text-5xl;
}

h2 {
    @apply font-headline text-4xl;
}

h3 {
    @apply font-headline text-3xl;
}

h4 {
    @apply font-headline text-2xl;
}

h5 {
    @apply font-headline text-xl;
}
/* purgecss end ignore */