/* Tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Base */
@import "base/button.css";
@import "base/embed.css";
@import "base/input.css";
@import "base/link.css";
@import "base/type.css";

@layer base {
    menu, ul, ol {
        list-style: revert;
        margin: revert;
        padding: revert;
    }
}

/*a:hover {
    border-bottom: 2px solid theme('colors.red.DEFAULT');
}*/

[x-cloak] {
    display: none !important;
}