/* purgecss start ignore */
input[type=text], input[type=number], input[type=email], input[type=password], input[type=tel], select.block, textarea {
    @apply mt-1 border border-gray-300 focus:border-gray-600 focus:ring focus:ring-gray-400 focus:ring-opacity-50 max-w-full p-3;
}

input[type=checkbox] {
    @apply border border-gray-300 focus:border-gray-600 focus:ring focus:ring-offset-0 focus:ring-gray-400 focus:ring-opacity-50;
}

.umbraco-forms-form .input-validation-error {
    @apply bg-red-50 text-red-500 border border-red-200;
}

.umbraco-forms-form .field-validation-error {
    @apply block bg-red-100 text-sm text-red-500 border border-red-200 p-2 mt-2 rounded-md;
}



/* purgecss end ignore */