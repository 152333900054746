.btn {
    @apply md:text-lg md:px-10 px-5 py-2 rounded-full disabled:opacity-50 transition;
}

.btn-primary {
    @apply bg-primary text-white hover:bg-[#B03733];
}

.btn-secondary {
    @apply bg-white text-black hover:bg-[#BBBFB4];
}